import React from 'react';
import { Link } from 'gatsby'

const NotFoundPage = () => (
	<>
		<Link to="/">Domů</Link>
		<h1 data-title-border="">404 - Stránka nenalezena</h1>
		<h2>404!</h2>
		<p>Ztraceni na cestách? Použijte naší navigaci</p>
	</>
);

export default NotFoundPage;
